<template>
  <div class=" font-test">
    <aboutsPageTopPc />
    <div class="content-box">
      <el-image :src="contentList[language]" width="100%" lazy @load="imgOnload">
        <div slot="placeholder" class="image-slot">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div v-show="['en','ja'].includes(language)" class="button-box" style="margin-top:25px">
        <el-image @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" style="cursor:pointer" :src="appleDownlaodBtn" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="language != 'zh'" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" style="cursor:pointer" :src="googleDownlaodBtn" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <div v-show="['zh','zh_tw','ko'].includes(language)" class="button-box">
        <el-image v-if="showBtn" @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" style="cursor:pointer" :src="appleDownlaodBtn" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn && language != 'zh'" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="googleDownlaodBtn" style="cursor:pointer" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
      </div>
    </div>
  </div>
</template>
<script>
import aboutsPageTopPc from '@/components/aboutsPage-top-pc'
import { downloadPageA_list_PC } from '@/utils/public_data'
export default {
  data() {
    return {
      contentList: downloadPageA_list_PC(),
      showBtn: false,
      appleDownlaodBtn: require('@/assets/images/downloadPage/A/button/PC_apple.png'),
      googleDownlaodBtn: require('@/assets/images/downloadPage/A/button/PC_GOOGLE.png')
    }
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  },
  components: {
    aboutsPageTopPc
  }
}
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.app-container {
  width: 90%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}
.button-box {
  position: absolute;
  top: 62.2%;
  left: 52.8%;
  z-index: 999;
  .el-image {
    width: 19%;
    margin: 0 5px;
  }
}
@media screen and (min-width: 768px) and(max-width: 1024px) {
  .button-box {
    position: absolute;
    top: 60%;
    left: 52.8%;
    z-index: 999;
  }
}
@media screen and (min-width: 1024px) and(max-width: 1440px) {
  .button-box {
    position: absolute;
    top: 61.4%;
    left: 52.8%;
    z-index: 999;
  }
}
@media screen and (min-width: 1440px) {
  .button-box {
    position: absolute;
    top: 62.4%;
    left: 52.8%;
    z-index: 999;
  }
}
</style>
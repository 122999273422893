<template>
  <div style="padding:1px">
    <div class="container">
      <!-- 头部导航 -->
      <aboutsPageTopMb />
    </div>
    <div class="content-box">
      <el-image :src="contentList[language]" width="100%" @load="imgOnload" lazy>
        <div slot="placeholder" class="image-slot">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>

      <div class="button-box">
        <el-image v-if="showBtn" @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appleDownlaodBtn" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn && language != 'zh'" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="googleDownlaodBtn" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
      </div>
    </div>
    <!-- <div v-show="language==='ja'">
      ダウンロードは保留中
    </div> -->

  </div>
</template>
<script>
import aboutsPageTopMb from '@/components/aboutsPage-top-mb'
import { downloadPageA_list_MB } from '@/utils/public_data'
export default {
  data() {
    return {
      contentList: downloadPageA_list_MB(),
      showBtn: false,
      appleDownlaodBtn: require('@/assets/images/downloadPage/A/button/MB_apple.png'),
      googleDownlaodBtn: require('@/assets/images/downloadPage/A/button/MB_GOOGLE.png')
    }
  },
    mounted(){
    // 进入页面回到顶部
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  },
  components: {
    aboutsPageTopMb
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  // padding: 0 16px;
  // box-sizing: border-box;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
}
.button-box {
  position: absolute;
  bottom: 20%;
  left: 8%;
  z-index: 999;
  display: flex;
  .el-image {
    width: 42%;
    margin: 0 5px;
  }
}
</style>